.footer_wrap{
    background-image: url("../componentgallery/footer_back.png");
    height: 300px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: 'Montserrat', sans-serif;
    padding: 40px;
    padding-bottom: 20px;
    padding-top: 62px;
}

.hyper_link ul li {
    list-style: none;
    color: #FFFFFF;
    font-size: 18px;
    margin: 10px;
    cursor: pointer;
    text-align: end;
}

.icons_social_media_container {
    display: flex;
    justify-content: center;
}

.heading_social_media p {
    font-size: 24px;
    margin: 10px;
    color: #FFFFFF;
    font-weight: 700;
}

.icons_sm {
    color: #FFFFFF;
    font-size: 34px;
    padding: 4px;
    cursor: pointer;
}

.footer_logo {
    width: 420px;
    margin-left: 40px;
    margin: 40px;
}

.copyright_content p {
    font-family: 'Montserrat', sans-serif;
    font-size: 12px;
    color: #FFFFFF;
    padding-left: 20px;
    padding-top: 5px;
    text-align: end;
    padding-right: 120px;
}

.hyper_link ul a{
    text-decoration: none;
}

@media screen and (max-width: 1450px) {
    .footer_logo{
        width: 380px;
    }
}

@media screen and (max-width: 1360px) {
    .footer_logo{
        width: 350px;
    }
}

@media screen and (max-width: 1300px) {
    .footer_logo{
        width: 300px;
    }
    .hyper_link ul li{
        font-size: 14px;
        margin: 5px;
    }
    .heading_social_media p{
        font-size: 20px;
        margin: 5px;
    }
    .icons_sm{
        font-size: 24px;
    }
}

@media screen and (max-width: 1200px) {
    .footer_logo{
        width: 250px;
    }
}

@media screen and (max-width: 1100px) {
    .footer_logo{
        width: 200px;
    }
}

@media screen and (max-width: 1000px) {
    .footer {
        padding-left: 4px;
    }
    .footer_logo{
        margin-left: 10px;
    }
}
@media screen and (max-width: 900px) {
    .footer {
        padding-left: 4px;
    }
    .footer_logo{
        margin-left: 10px;
        width: 160px;
    }
}
@media screen and (max-width: 800px) {
    .footer_wrap{
        height: 260px;
    }
}

@media screen and (max-width: 750px) {
    .footer_wrap{
        height: 230px;
    }
    .hyper_link ul li{
        font-size: 12px;
        margin: 5px;
    }
    .heading_social_media p{
        font-size: 16px;
        margin: 5px;
    }
    .icons_sm{
        font-size: 17px;
    }
}

@media screen and (max-width: 707px) {
    .footer_wrap{
        height: 230px;
    }
    .footer_logo{
        width: 160px;
        background-color: #fff;
    }
}

@media screen and (max-width: 540px) {
    .hyper_link ul li{
        font-size: 10px;
    }
    .heading_social_media p{
        font-size: 14px;
    }
    .icons_sm{
        font-size: 15px;
    }
    .footer{
        padding-right: 3px;
    }
}
