.navbar{
    display: flex;
    justify-content: space-between;
    margin: 15px 35px;
    align-items: center;
    z-index: 1000;
}
.logo{
    width: 300px;
    cursor: pointer;
}
.items{
    width: 40%;
}
.items ul{
    display: flex;
    justify-content: flex-end;
}
.items ul a{
    text-decoration: none;
}
.items ul li{
    list-style: none;
    color: #6C6C6C;
    font-family: 'Fira Code', monospace;
    font-size: 24px;
    font-weight: 500;
    cursor: pointer;
    padding-left: 40px;
}
.items ul li:hover{
    color: #000000;
}
.active-link{
    color: #000000;
}

@media screen and (max-width: 1320px) {
    .logo{
        width: 275px;
    }
    .items ul li{
        font-size: 22px;
        padding-left: 30px;
    }
}

@media screen and (max-width: 1180px) {
    .logo{
        width: 200px;
    }
    .items{
        width: 50%;
    }
    .items ul li{
        font-size: 20px;
        padding-left: 25px;
    }
}

@media screen and (max-width: 900px) {
    .logo{
        width: 200px;
    }
    .items{
        width: 60%;
    }
    .items ul li{
        font-size: 20px;
    }
}


@media screen and (max-width: 750px) {
    .logo{
        width: 170px;
    }
    .items{
        width: 60%;
    }
    .items ul li{
        font-size: 19px;
        padding-left: 20px;
    }
}


@media screen and (max-width: 650px) {
    .navbar{
        margin: 30px 25px;
    }
    .logo{
        width: 160px;
    }
    .items{
        width: 60%;
    }
    .items ul li{
        font-size: 18px;
        padding-left: 10px;
    }
}

@media screen and (max-width: 580px) {
    .navbar{
        margin: 30px 20px;
    }
    .logo{
        width: 150px;
    }
    .items{
        width: 63%;
    }
    .items ul li{
        font-size: 16px;
        padding-left:4px ;
    }
}

@media screen and (max-width: 530px) {
    .navbar{
        margin: 25px 10px;
    }
    .logo{
        width: 140px;
    }
    .items{
        width: 65%;
    }
    .items ul li{
        font-size: 16px;
    }
}

@media screen and (max-width: 500px) {
    .navbar{
        margin: 25px 10px;
    }
    .logo{
        width: 120px;
    }
    .items{
        width: 65%;
    }
    .items ul li{
        font-size: 14px;
    }
}

@media screen and (max-width: 450px) {
    .navbar{
        margin: 25px 10px;
    }
    .logo{
        width: 110px;
    }
    .items{
        width: 65%;
    }
    .items ul li{
        font-size: 13px;
    }
}