.gallery_heading_container{
    display: flex;
    justify-content: center;
    border-bottom: 1px solid rgb(51, 51, 51,50%);
    padding: 20px;
    margin-bottom:20px ;
}
.gallery_heading p{
    color: rgb(51, 51, 51,80%);
    font-size: 32px;
}

.images_gallery_container{
    display: grid;
    grid-template-columns: repeat(5,1fr);
    gap: 10px;
    margin-bottom: 20px;
}


@media only screen and (max-width: 1400px){
    .images_gallery_container{
        grid-template-columns: repeat(4,1fr);
    }
}
@media only screen and (max-width: 1200px){
    .images_gallery_container{
        grid-template-columns: repeat(3,1fr);
    }
}
@media only screen and (max-width: 900px){
    .images_gallery_container{
        grid-template-columns: repeat(2,1fr);
    }
}